/**
 * Evaluation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Question } from './question';
import { CourseType } from './courseType';


export interface Evaluation { 
    id?: number;
    name: string;
    siteConfigId: number;
    scaleDirection?: Evaluation.ScaleDirectionEnum;
    defaultTypes?: Array<CourseType>;
    questions: Array<Question>;
}
export namespace Evaluation {
    export type ScaleDirectionEnum = 'DESC' | 'ASC';
    export const ScaleDirectionEnum = {
        Desc: 'DESC' as ScaleDirectionEnum,
        Asc: 'ASC' as ScaleDirectionEnum
    };
}


