/**
 * Evaluation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Question { 
    id?: number;
    type: Question.TypeEnum;
    position: number;
    question: string;
    answers?: Array<string>;
    scaleStart?: number;
    scaleEnd?: number;
    comment?: boolean;
}
export namespace Question {
    export type TypeEnum = 'HEADLINE' | 'FREE' | 'GRADE' | 'MULTIPLE_CHOICE';
    export const TypeEnum = {
        Headline: 'HEADLINE' as TypeEnum,
        Free: 'FREE' as TypeEnum,
        Grade: 'GRADE' as TypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum
    };
}


